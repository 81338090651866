import { For, Match, Switch } from "solid-js";
import { Link, Meta, Title } from "@solidjs/meta";

type MetaTag = {
  tag: string;
  attributes: Record<string, string>;
};

export function MetaTags(props: { tags: MetaTag[] }) {
  return (
    <>
      <For each={props.tags}>
        {(tag) => (
          <Switch>
            <Match when={tag.tag === "meta" && tag.attributes.name === "title"}>
              <Title>{tag.attributes.content}</Title>
            </Match>
            <Match when={tag.tag === "meta"}>
              <Meta {...tag.attributes} />
            </Match>
            <Match when={tag.tag === "link"}>
              <Link
                {...{
                  ...tag.attributes,
                  href: tag.attributes.href?.startsWith("http://")
                    ? tag.attributes.href.replace("http://", "https://")
                    : tag.attributes.href,
                }}
              />
            </Match>
          </Switch>
        )}
      </For>
    </>
  );
}
